export const outerLinks = {
  signUp: 'https://app.diment.io/auth/sign-up',
  signIn: 'https://app.diment.io/auth/sign-in',
  socials: {
    twitter: 'https://x.com/diment_official',
    telegram: 'https://t.me/dimentofficial',
    instagram: 'https://www.instagram.com/diment.official',
    facebook: 'https://www.facebook.com/dimentofficial',
    medium: 'https://medium.com/@dimentofficial',
    linkedin: 'https://linkedin.com/company/dimentofficial',
    supportEmail: 'mailto:support@diment.io',
    contactPhone: 'tel:+97142391555',
    reddit: 'https://www.reddit.com/r/Diment',
    youtube: 'https://youtube.com/@dimentofficial',
  },
  network: {
    certic: 'https://www.certik.com',
    antwerpsche: 'http://diamantkring.be',
    kimberley: 'https://www.kimberleyprocess.com',
    gia: 'https://www.gia.edu',
    gifs: 'https://www.gifsrv.com/',
    ifsg: 'https://www.gifsrv.com/',
    hrd: 'https://hrdantwerp.com',
    igi: 'https://www.igi.org',
    wfdb: 'https://wfdb.com',
    drie: 'https://www.driesassur.com',
    dimentreserve: 'https://www.diment.ch',
    ida: 'http://www.theida.net/',
    aig: 'https://www.aig.be/',
    diavault: 'https://diavault.com/',
  },
  mobileApp: {
    googlePlay:
      'https://play.google.com/store/apps/details?id=io.diment.app.android',
  },
  buyToken: 'https://app.diment.io/buy-token',
};
