import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { headerNavLinks } from '../constants';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { colors } from '@/styles/theme/colors';

type NavLinksProps = { onNavClick?: () => void };

const NavLinks = ({
  onNavClick,
  isMobile = false,
}: NavLinksProps & { isMobile?: boolean }) => {
  const { t } = useTranslation('main');
  const router = useRouter();

  return (
    <>
      {headerNavLinks?.map((navLink, index) => (
        <Link key={index} href={navLink.href}>
          <Box
            onClick={onNavClick}
            pb={{ base: 4, md: 0 }}
            _hover={{
              transform: 'translateY(-2px)',
              cursor: 'pointer',
            }}
            transitionDuration={'0.3s'}
            transitionTimingFunction={'ease-in-out'}
            fontWeight={router.pathname == navLink.href ? '800' : '500'}
            color={'inherit'}
            position='relative'
            _after={
              router.pathname == navLink.href
                ? {
                    content: '""',
                    position: 'absolute',
                    top: 8,
                    bottom: 0,
                    left: isMobile ? 0 : '50%',
                    transform: isMobile ? 'none' : 'translateX(-50%)',
                    height: '2px',
                    width: '20px',
                    bg: colors.diment.lightBlue,
                  }
                : {}
            }
          >
            {t(navLink.label)}
          </Box>
        </Link>
      ))}
    </>
  );
};

export default NavLinks;
