import { colors } from '@/styles/theme/colors';
import { outerLinks } from '@/utils/constants/outer-links';
import { Box, IconButton, SimpleGrid } from '@chakra-ui/react';
import Link from 'next/link';
import { FaTelegramPlane } from 'react-icons/fa';
import {
  FaMedium,
  FaEnvelope,
  FaXTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaReddit,
  FaYoutube,
} from 'react-icons/fa6';

export const socials = [
  { Icon: FaXTwitter, href: outerLinks.socials.twitter },
  { Icon: FaTelegramPlane, href: outerLinks.socials.telegram },
  { Icon: FaInstagram, href: outerLinks.socials.instagram },
  { Icon: FaFacebookF, href: outerLinks.socials.facebook },
  { Icon: FaMedium, href: outerLinks.socials.medium },
  { Icon: FaLinkedinIn, href: outerLinks.socials.linkedin },
  { Icon: FaReddit, href: outerLinks.socials.reddit },
  { Icon: FaYoutube, href: outerLinks.socials.youtube },
  // { Icon: FaEnvelope, href: outerLinks.socials.supportEmail },
];

type SocialProps = {
  color?: string;
  bg?: string;
};

const Socials: React.FC<SocialProps> = ({
  color = colors.diment.black,
  bg = 'rgba(100, 100, 100, 0.15)',
}) => {
  return (
    <SimpleGrid columns={{ base: 4, sm: 8 }} spacing={2}>
      {socials.map((social, index) => (
        <Link key={index} href={social.href} target='_blank'>
          <Box
            w={10}
            h={10}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            bg={bg}
          >
            <IconButton
              w={'full'}
              h={'full'}
              rounded={'none'}
              variant={'ghost'}
              aria-label='social'
              icon={<Box size={'24px'} as={social.Icon} color={color} />}
              bgColor={'transparent'}
            />
          </Box>
        </Link>
      ))}
    </SimpleGrid>
  );
};

export default Socials;
